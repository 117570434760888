import CoreLayout, { Props as CoreLayoutProps } from '@/layouts/core/components/CoreLayout';
import PublicFooter from '@/layouts/public/components/PublicFooter';
import PublicNav from '@/layouts/public/components/PublicNav';
import { createLogger } from '@/modules/core/logging/logger';
import { css } from '@emotion/react';
import React from 'react';

const fileLabel = 'layouts/public/components/PublicLayout';
const logger = createLogger({
  fileLabel,
});

type Props = Omit<CoreLayoutProps, 'layoutName'>;

/**
 * Overrides the CoreLayout to adapt it to the Public layout.
 *
 * Displays nav, footer and preview banner for all public pages.
 */
const PublicLayout: React.FunctionComponent<Props> = (props): JSX.Element => {
  return (
    <CoreLayout
      layoutName={'public-layout'}
      Nav={PublicNav}
      hideNav={false}
      Footer={PublicFooter}
      hideFooter={false}
      // Only show the preview banner in staging env
      hidePreviewBanner={process.env.NEXT_PUBLIC_APP_STAGE !== 'staging'}
      {...props}
    />
  );
};

export default PublicLayout;
