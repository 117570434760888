import React from 'react';

/**
 * FYI WIP not really used, need to provide a "hasError" prop, which we don't do at this time
 * @see https://nextjs.org/docs/pages/building-your-application/configuring/error-handling
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({error, errorInfo});
  }

  render() {
    // Check if the error is thrown
    // @ts-ignore
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Une erreur est survenue.</h2>
          <button
            type="button"
            onClick={() => this.setState({hasError: false})}
          >
            Rafraîchir la page peut résoudre le problème.
          </button>
        </div>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
