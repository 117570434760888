import AirtableAsset from '@/modules/core/airtable/components/AirtableAsset';
import { LogEvent } from '@/modules/core/amplitude/types/Amplitude';
import useCustomer from '@/modules/core/data/hooks/useCustomer';
import { Customer } from '@/modules/core/data/types/Customer';
import I18nBtnChangeLocale from '@/modules/core/i18n/components/I18nBtnChangeLocale';
import I18nLink from '@/modules/core/i18n/components/I18nLink';
import useI18n, { I18n } from '@/modules/core/i18n/hooks/useI18n';
import {
  isActive,
  resolveI18nHomePage,
} from '@/modules/core/i18n/i18nRouter';
import { Amplitude } from '@amplitude/react-amplitude';
import {
  css,
  useTheme,
} from '@emotion/react';
import {
  NextRouter,
  useRouter,
} from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Nav as NavStrap,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap';

type Props = {};

const PublicNav: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const { locale }: I18n = useI18n();
  const customer: Customer = useCustomer();
  const router: NextRouter = useRouter();
  const theme = useTheme();

  const {
    primaryColor,
    backgroundColor,
    serviceLogo,
  } = theme;
  const { availableLanguages } = customer || {};
  const shouldDisplayI18nButton = availableLanguages?.length > 1;
  const {
    modChatbot,
    modSLS,
  } = customer || {};
  const isChatbotModuleEnabled = modChatbot?.isEnabled;
  const isSLSModuleEnabled = modSLS?.isEnabled;

  return (
    <Amplitude>
      {({ logEvent }: { logEvent: LogEvent }): JSX.Element => (
        <Navbar
          id={'nav'}
          css={css`
            background-color: ${backgroundColor};
            align-items: center;

            @media (min-width: 992px) {
              margin-left: 80px;
              margin-right: 80px;
            }

            @media (max-width: 991.98px) {
              margin-left: 10px;
              margin-right: 10px;

              li {
                margin: 10px !important;
              }
            }

            @media (max-width: 350px) {
              padding: 0 !important;
            }

            .navbar-nav {
              flex-direction: row;

              li {
                margin: 10px 20px;
                text-align: center;
                justify-content: center;

                a {
                  cursor: pointer;
                  color: #000 !important;
                }
              }
            }

            .nav-link {
              &.active {
                font-weight: bold;
                color: ${primaryColor} !important;
              }
            }

            .dropdown {
              padding-top: 8px;
              padding-bottom: 8px;
              cursor: pointer;

              .dropdown-toggle {
                &.active {
                  color: ${primaryColor};
                }
              }

              .dropdown-menu {
                z-index: 10000;
              }

              .dropdown-item {
                max-height: 30px;
                padding-top: 0;

                .nav-link {
                  padding: 4px;
                }
              }
            }

            .dropdown-header,
            .dropdown-divider {
              cursor: initial;
            }

            .nav-i18n-button-container {
              display: none; // Hide button by default

              @media (max-width: 991.98px) {
                display: flex; // Display button on small devices, to make it easier to change language (avoid scrolling the whole page)
                flex-direction: row-reverse;
                width: 100%;
              }
            }
          `}
        >
          {
            shouldDisplayI18nButton && (
              <div className={'nav-i18n-button-container'}>
                <I18nBtnChangeLocale id={'nav-btn-change-locale'} />
              </div>
            )
          }
          <div className={'brand-logo'}>
            <AirtableAsset
              id={'nav-logo-brand'}
              asset={serviceLogo}
              linkOverride={{ id: 'nav-open-app-link', url: resolveI18nHomePage(locale)?.i18nHref || '/', target: null }} // Force link to redirect to home
            />
          </div>
          <NavStrap navbar>
            <NavItem>
              <I18nLink
                href={`/`}
                wrapChildrenAsLink={false}
              >
                <NavLink
                  id={'nav-link-home'}
                  active={isActive(router, '')}
                >
                  {t('nav.indexPage.link', 'Accueil')}
                </NavLink>
              </I18nLink>
            </NavItem>

            {
              isChatbotModuleEnabled && (
                <NavItem>
                  <I18nLink
                    href={`/chatbot`}
                    wrapChildrenAsLink={false}
                  >
                    <NavLink
                      id={'nav-link-chatbot'}
                      active={isActive(router, 'chatbot')}
                    >
                      {t('nav.chatbotPage.link', 'Chatbot')}
                    </NavLink>
                  </I18nLink>
                </NavItem>
              )
            }

            {
              // If the chatbot module isn't enabled then we display a link to go to the solutions directly
              !isChatbotModuleEnabled && (
                <NavItem>
                  <I18nLink
                    href={`/solutions`}
                    wrapChildrenAsLink={false}
                  >
                    <NavLink
                      id={'nav-link-solutions'}
                      active={isActive(router, 'solutions')}
                    >
                      {t('nav.solutionsPage.link', 'Solutions')}
                    </NavLink>
                  </I18nLink>
                </NavItem>
              )
            }

            {
              isSLSModuleEnabled && (
                <NavItem>
                  <I18nLink
                    href={`/simulator`}
                    wrapChildrenAsLink={false}
                  >
                    <NavLink
                      active={isActive(router, 'simulator')}
                    >
                      {t('nav.simulatorPage.link', 'Simulateur')}
                    </NavLink>
                  </I18nLink>
                </NavItem>
              )
            }
          </NavStrap>
        </Navbar>
      )}
    </Amplitude>
  );
};

export default PublicNav;
