/**
 * Event names.
 */
export enum AMPLITUDE_EVENTS {
  REPORT_WEB_VITALS = 'report-web-vitals', // When the Core Web Vitals report is sent automatically on any page load
  USER_CONSENT_MANUALLY_GIVEN = 'user-consent-manually-given', // When the user makes a manual choice regarding cookies consent
  CHATBOT_ANSWER = 'chatbot-answer', // When the user selects an answer in the chatbot
  SOLUTIONS_FILTER_CHANGED = 'solutions-filter-changed', // When the user changes a filter in the solutions page
  API_INVOKED = 'api-invoked', // When any API is invoked
  API_LOCALE_MIDDLEWARE_INVOKED = 'api-locale-middleware-invoked', // When the "localeMiddleware" API is invoked
}

/**
 * Event actions.
 *
 * We use an "action" property to track the event's trigger.
 * It's especially useful when the same event can be triggered by different actions,
 * as sometimes it's easier to keep a single event with different properties. (it really depends how you want to use the data)
 *
 * Best practice: All actions must use action verb (imperative form).
 * This is a NRN internal rule (recommandation) about how to track which action led to triggering the event.
 *
 * DA Usefulness: Avoids using anonymous constants that will likely end up being duplicated.
 *  Using constants ensures strict usage with a proper definition for the analytics team and the developers.
 *  Example: Using both "remove" and "delete" could lead to misunderstanding or errors when configuring charts.
 */
export enum AMPLITUDE_ACTIONS {
  CLICK = 'click', // When an element is clicked (mouse) or tapped (screen, mobile)
  SELECT = 'select', // When an element is selected (checkbox, select input, multi choices)
  REMOVE = 'remove', // When an element is removed/delete
  OPEN = 'open', // When an element is opened
  CLOSE = 'close', // When an element is closed
  AUTO = 'auto', // When an event is triggered automatically instead of a user action
}

/**
 * Pages names used within Amplitude.
 *
 * Each page within the /src/pages directory should use a different page name as "pageName".
 * This is used to track events happening within the pages, to know on which page they occurred.
 */
export enum AMPLITUDE_PAGES {
  HOME_PAGE = 'home',
  PREVIEW_HOME_PAGE = 'preview-home',
  SOLUTIONS_PAGE = 'solutions',
  PREVIEW_SOLUTIONS_PAGE = 'preview-solutions',
  SIMULATOR_PAGE = 'simulator',
  CHATBOT_PAGE = 'chatbot',
  TERMS_PAGE = 'terms',
  PRIVACY_PAGE = 'privacy',
  TEMPLATE_SSG_PAGE = 'template-ssg',
  TEMPLATE_SSR_PAGE = 'template-ssr',
}

/**
 * API endpoint names.
 *
 * Each API endpoint within the src/pages/api directory should use a different endpoint name.
 * This is used to track events happening within the API endpoints, to know on which endpoint they occurred.
 */
export enum AMPLITUDE_API_ENDPOINTS {
  STATUS = 'status',
  AUTO_REDIRECT_TO_LOCALISED_PAGE = 'autoRedirectToLocalisedPage',
  START_VERCEL_DEPLOYMENT = 'startVercelDeployment',
  ERROR = 'error',
  PREVIEW = 'preview',
  WEBHOOK_DEPLOYMENT_COMPLETED = 'deploymentCompleted',
  ANALYZE_IMAGE = 'analyzeImage',
  SYNC_PRODUCTION_PLATFORM = 'syncProductionPlatform',
}

/**
 * Direction used when navigating between tuition fees.
 *
 * DA Usefulness: Unknown, tracked "in case of", no real analytic usage ATM
 */
export enum AMPLITUDE_TUITION_FEES_DIRECTIONS {
  PREVIOUS = 'previous',
  NEXT = 'next',
}

/**
 * "Group by" choices for solutions.
 */
export enum AMPLITUDE_SOLUTIONS_GROUP_BY {
  EDUCATIONAL_PROGRAM = 'educationalProgram',
  CAMPUS = 'campus',
  CATEGORY = 'category',
}

/**
 * External link types.
 */
export enum AMPLITUDE_EXTERNAL_LINK_TYPES {
  WEBSITE = 'website',
  DOCUMENT = 'document',
}

/**
 * Contact information types.
 */
export enum AMPLITUDE_CONTACT_TYPES {
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum AMPLITUDE_FEEDBACK_SURVEY_STEPS {
  ASK_GRADE = 'ASK_GRADE',
  ASK_COMMENT = 'ASK_COMMENT',
  THANK_YOU = 'THANK_YOU',
}
