import {
  config,
  library,
} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
  faAddressCard as faAddressCardLight,
  faClock as faClockLight,
  faFileAlt as faFileAltLight,
  faInfoCircle as faInfoCircleLight,
  faMapMarker as faMapMarkerLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faArrowUp,
  faAt,
  faCopy,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faInfoCircle,
  faLink,
  faMapMarkedAlt,
  faPaperPlane,
  faPen,
  faPhoneAlt,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';

// See https://github.com/FortAwesome/react-fontawesome#integrating-with-other-tools-and-frameworks
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

/**
 * Configure the Font-Awesome icons library by pre-registering icon definitions so that we do not have to explicitly pass them to render an icon.
 * Necessary for proper server-side rendering of icons.
 *
 * XXX Since Next.js 10, it is possible to import CSS file outside of the _app.tsx file.
 *  We leverage this new feature to configure our Font-Awesome icons outside of _app to avoid cluttering that file.
 *
 * @example <FontAwesomeIcon icon={['fas', 'home']} />
 *
 * @see https://fontawesome.com/how-to-use/javascript-api/methods/library-add
 * @see https://nextjs.org/blog/next-10#importing-css-from-third-party-react-components
 */

// Import @fortawesome/pro-light-svg-icons
library.add(
  faAddressCardLight,
  faClockLight,
  faFileAltLight,
  faInfoCircleLight,
  faMapMarkerLight,
);

// Import @fortawesome/pro-solid-svg-icons
library.add(
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faArrowUp,
  faArrowDown,
  faAt,
  faCopy,
  faQuestionCircle,
  faEnvelope,
  faExternalLinkAlt,
  faEye,
  faInfoCircle,
  faLink,
  faMapMarkedAlt,
  faPaperPlane,
  faPen,
  faPhoneAlt,
  faPlusCircle,
  faSearch,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUsers,
  faSync,
  faExclamationTriangle,
);
